var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"py-2 d-flex flex-wrap"},[(_vm.$root.acceso('CREAR_CONTRATO'))?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"borradores/cargar_contrato"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v("Cargar contrato borrador ")],1):_vm._e(),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getBorradores}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1),_c('v-dialog',{attrs:{"max-width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chart-bar")]),_vm._v("Contratos por mes ")],1)]}}])},[_c('v-card',{staticClass:"pa-2"},[_c('contratos-por-mes')],1)],1),_c('v-switch',{staticClass:"mt-0 ml-1 align-self-center",attrs:{"label":"Ver Enviados","hide-details":""},model:{value:(_vm.verEnviados),callback:function ($$v) {_vm.verEnviados=$$v},expression:"verEnviados"}}),_c('v-spacer'),(_vm.$root.acceso('CAMBIAR_ESTADOS_CONTRATO'))?_c('v-btn',{attrs:{"disabled":_vm.selected.length == 0,"text":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.eliminarBorradores('Eliminado')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Eliminar ")],1):_vm._e(),(_vm.$root.acceso('DESCARGAR_CSV_CONTRATOS'))?_c('v-btn',{attrs:{"text":"","color":"primary","small":"","disabled":_vm.selected.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.exportarTodos(_vm.selected)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Descargar seleccionados ")],1):_vm._e()],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.borradoresFiltrados,"headers":_vm.contratosHeader,"show-select":"","item-key":"id","single-select":false,"calculate-widths":true,"loading":_vm.loading,"footer-props":{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				},"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
				var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.borradoresFiltrados},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.Estado",fn:function(ref){
				var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.Estado}},[(item.Estado != 'Borrador' && _vm.$root.acceso('CAMBIAR_ESTADOS_CONTRATO'))?_c('v-icon',{attrs:{"right":""},on:{"click":function($event){return _vm.eliminarBorradores('Borrador', item.id)}}},[_vm._v("mdi-cached")]):_vm._e()],1)]}},{key:"item.empresa",fn:function(ref){
				var item = ref.item;
return [(item.empresa)?_c('empresa-chip',{attrs:{"value":item.empresa}}):_vm._e()]}},{key:"item.acciones",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.Estado == 'Borrador')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":("borradores/cargar_contrato?id=" + (item.id)),"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":("/borradores/detalles?id=" + (item.id)),"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.generatingPDF,"loading":_vm.generatingPDF && _vm.imprimirId == item.id},on:{"click":function($event){_vm.imprimirId = item.id;
									_vm.generarPDF();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir contrato")])])],1)]}},{key:"item.FechaAlta",fn:function(ref){
									var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaAlta))}})]}},{key:"item.FechaVto",fn:function(ref){
									var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaVto))}})]}},{key:"item.Tarifa",fn:function(ref){
									var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.Tarifa),expression:"item.Tarifa"}],attrs:{"label":"","small":""},domProps:{"textContent":_vm._s(item.Tarifa)}})]}},{key:"footer.prepend",fn:function(){return [_c('status-chip',{attrs:{"value":_vm.$store.getters.getEntorno}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.$root.acceso('DESCARGAR_CSV_CONTRATOS'))?_c('div',{staticClass:"d-flex flex-wrap mt-4",staticStyle:{"gap":"16px"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.exportarTodos}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Exportar todos ")],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.tipoFirma),callback:function ($$v) {_vm.tipoFirma=$$v},expression:"tipoFirma"}},[_c('v-card',{staticClass:"pa-4",attrs:{"width":"100%"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.generarPDF($event)}}},[_vm._v("Firma manual")]),_c('v-spacer'),_c('span',{staticClass:"subtitle-1"},[_vm._v("O")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){$event.stopPropagation();_vm.firmaDigital = true;
						_vm.tipoFirma = false;}}},[_vm._v("Firma digital")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.firmaDigital),callback:function ($$v) {_vm.firmaDigital=$$v},expression:"firmaDigital"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"display":"grid","grid-template-rows":"0fr 1fr"}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.firmaDigital = false;
					_vm.generatingPDF = false;}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.bus.$emit('clear')}}},[_vm._v("Borrar")]),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.bus.$emit('save')}}},[_vm._v("Guardar")])],1)],1),_c('signature-pad',{key:String(_vm.imprimirId) + String(_vm.firmaDigital),attrs:{"bus":_vm.bus},on:{"firmaGuardada":function (firma) { return _vm.generarPDF(null, (firma = firma)); }}})],1)],1),_c('portal-target',{attrs:{"name":"filter-btn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }