<template>
	<v-container fluid>
		<div class="py-2 d-flex flex-wrap">
			<v-btn
				v-if="$root.acceso('CREAR_CONTRATO')"
				class="ma-1"
				color="primary"
				small
				to="borradores/cargar_contrato"
			>
				<v-icon left>mdi-account-plus</v-icon>Cargar contrato borrador
			</v-btn>
			<v-btn class="ma-1" color="primary" small @click="getBorradores">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-dialog max-width="1000">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" class="ma-1" color="primary" small>
						<v-icon left>mdi-chart-bar</v-icon>Contratos por mes
					</v-btn>
				</template>
				<v-card class="pa-2">
					<contratos-por-mes />
				</v-card>
			</v-dialog>

			<v-switch
				class="mt-0 ml-1 align-self-center"
				label="Ver Enviados"
				hide-details
				v-model="verEnviados"
			></v-switch>

			<v-spacer />
			<!-- <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda..."
        single-line
        hide-details
        filled
			></v-text-field>-->
			<v-btn
				@click.stop="eliminarBorradores('Eliminado')"
				v-if="$root.acceso('CAMBIAR_ESTADOS_CONTRATO')"
				:disabled="selected.length == 0"
				text
				color="error"
			>
				<v-icon left>mdi-delete</v-icon>Eliminar
			</v-btn>
			<v-btn
				v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS')"
				@click.stop="exportarTodos(selected)"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>Descargar seleccionados
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="borradoresFiltrados"
				:headers="contratosHeader"
				show-select class="fixed-checkbox"
				item-key="id"
				v-model="selected"
				:single-select="false"
				:calculate-widths="true"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="borradoresFiltrados" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.Estado="{ item }">
					<status-chip :value="item.Estado">
						<v-icon
							@click="eliminarBorradores('Borrador', item.id)"
							v-if="item.Estado != 'Borrador' && $root.acceso('CAMBIAR_ESTADOS_CONTRATO')"
							right
						>mdi-cached</v-icon>
					</status-chip>
				</template>
				<template v-slot:item.empresa="{ item }">
					<empresa-chip v-if="item.empresa" :value="item.empresa"></empresa-chip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<div class="d-flex">
						<v-tooltip bottom v-if="item.Estado == 'Borrador'">
							<template v-slot:activator="{ on, attrs }">
								<v-btn :to="`borradores/cargar_contrato?id=${item.id}`" color="primary" v-on="on" v-bind="attrs" icon>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Editar</span>
						</v-tooltip>
						<v-tooltip v-else bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									:to="`/borradores/detalles?id=${item.id}`"
									color="primary"
									v-on="on"
									v-bind="attrs"
									icon
								>
									<v-icon>mdi-format-list-bulleted-square</v-icon>
								</v-btn>
							</template>
							<span>Ver detalles</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									color="primary"
									v-on="on"
									v-bind="attrs"
									@click="
									imprimirId = item.id;
									generarPDF();
									"
									:disabled="generatingPDF"
									:loading="generatingPDF && imprimirId == item.id"
								>
									<v-icon>mdi-printer</v-icon>
								</v-btn>
							</template>
							<span>Imprimir contrato</span>
						</v-tooltip>
					</div>
				</template>

				<template v-slot:item.FechaAlta="{ item }">
					<span v-text="parseDate(item.FechaAlta)" />
				</template>
				<template v-slot:item.FechaVto="{ item }">
					<span v-text="parseDate(item.FechaVto)" />
				</template>

				<template v-slot:item.Tarifa="{ item }">
					<v-chip v-show="item.Tarifa" label small v-text="item.Tarifa" />
				</template>

				<template v-slot:footer.prepend>
					<status-chip :value="$store.getters.getEntorno"></status-chip>
				</template>
			</v-data-table>
		</v-card>

		<div
			class="d-flex flex-wrap mt-4"
			style="gap: 16px"
			v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS')"
		>
			<v-btn text color="primary" @click="exportarTodos">
				<v-icon left>mdi-download</v-icon>Exportar todos
			</v-btn>
		</div>

		<v-dialog v-model="tipoFirma" persistent max-width="400px">
			<v-card class="pa-4" width="100%">
				<v-card-actions>
					<v-btn color="primary" text @click.stop="generarPDF">Firma manual</v-btn>
					<v-spacer />
					<span class="subtitle-1">O</span>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click.stop="
						firmaDigital = true;
						tipoFirma = false;
						"
					>Firma digital</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="firmaDigital" persistent fullscreen transition="dialog-bottom-transition">
			<v-card class="pa-4" style="display: grid; grid-template-rows: 0fr 1fr">
				<v-toolbar dense dark color="primary">
					<v-btn icon @click="
					firmaDigital = false;
					generatingPDF = false;
					">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-spacer />
					<v-toolbar-items>
						<v-btn dark text @click="bus.$emit('clear')">Borrar</v-btn>
						<v-btn dark text @click="bus.$emit('save')">Guardar</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<signature-pad
					@firmaGuardada="(firma) => generarPDF(null, (firma = firma))"
					:bus="bus"
					:key="String(imprimirId) + String(firmaDigital)"
				></signature-pad>
			</v-card>
		</v-dialog>

		<portal-target name="filter-btn" />
	</v-container>
</template>

<script>
import Vue from "vue";
// import axios from "redaxios";
import {
	parseDate,
	getSituacionIcon,
	perColumnFilter,
	decode64,
	downloadFile,
	jsonToCsv
} from "@/utils/index.js";

export default {
	components: {
		SignaturePad: () => import("@/components/SignaturePad.vue"),
		ContratosPorMes: () => import("../components/ContratosPorMes.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
		EmpresaChip: () => import("@/components/EmpresaChip.vue"),
	},
	data() {
		return {
			bus: new Vue(),
			firmaDigital: false,
			tipoFirma: false,
			debounce: null,
			imprimirId: null,
			generatingPDF: false,
			loading: true,
			selected: [],
			contratos: [],
			inlineFilters: {},
			verEnviados: false,
			contratosHeader: [
				{ text: "Estado", value: "Estado", dataType: "select" },
				{
					text: "Acciones",
					value: "acciones",
					align: "center",
					sortable: false,
					filterable: false,
				},
				{ text: "Borrador", value: "id", align: "center" },
				{ text: "Agente", value: "NombreAgente" },
				{ text: "Subagente", value: "NombreSubAgente" },
				{ text: "Grupo Tarifa", value: "TarifaGrupo" },
				{ text: "DNI/NIE", value: "Identificador" },
				{ text: "Nombre", value: "nombreCompleto" },
				{ text: "CUPS", value: "CodigoCUPS" },
				{ text: "Direccion Cups", value: "direccionCUPS" },
				{ text: "Ciudad Cups", value: "CallePuntoSuministro" },
				{ text: "Fecha Alta", value: "FechaAlta", dataType: "date" },
				{ text: "Fecha Baja", value: "FechaVto", dataType: "date" },
				{ text: "Consumo Estimado", value: "ConsumoEstimado", align: "end" },
				{ text: "P1", value: "P1", align: "end" },
				{ text: "P2", value: "P2", align: "end" },
				{ text: "P3", value: "P3", align: "end" },
				{ text: "P4", value: "P4", align: "end" },
				{ text: "P5", value: "P5", align: "end" },
				{ text: "P6", value: "P6", align: "end" },
				{ text: "Tarifa", value: "Tarifa", dataType: "select" },
				{ text: "Deuda", value: "Deuda", align: "end" },
			].map((header) => ({
				class: "text-no-wrap sticky-header",
				cellClass: "pa-2 text-no-wrap",
				filter: (value) =>
					perColumnFilter(
						value,
						this.inlineFilters[header.value],
						header.dataType
					),
				dataType: "text",
				...header,
			})
			)
		};
	},
	computed: {
		borradoresFiltrados() {
			return this.verEnviados
				? this.contratos
				: this.contratos.filter((b) => b.Estado != "Enviado a verificación");
		},
	},
	methods: {
		getSituacionIcon,
		parseDate,
		eliminarBorradores(estado, id) {
			if (
				!confirm(
					`¿Estás seguro de que quieres cambiar el estado a ${estado || "eliminado"
					}?`
				)
			)
				return;
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_OUR_API_URL}/eliminar_borradores.php`,
				data: {
					token: this.$store.getters.getJwtEmpresa,
					borradores: !!id ? [id] : this.selected.map((b) => b.id),
					estado,
				},
			}).then((res) => this.getBorradores());
		},
		getBorradores() {
			this.loading = true;
			this.selected = [];
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/contratos.php`,
				headers: {
					Authorization: this.$store.getters.getToken,
				},
				params: {
					token: this.$store.getters.getJwtEmpresa,
					entorno: this.$store.getters.getEntorno,
				},
			})
				.then((res) => {
					this.contratos = res.data
						.map((borrador) => {
							return {
								nombreCompleto:
									[
										borrador.NombreTitular,
										borrador.Apellido1Titular,
										borrador.Apellido2Titular,
									]
										.filter((item) => !!item)
										.join(" ") || borrador.RazonSocialTitular,
								direccionCUPS: [
									borrador.CiudadSuministro,
									borrador.CallePuntoSuministro,
									borrador.Numero,
									borrador.Aclarador,
								]
									.filter((item) => !!item)
									.join(", "),
								...borrador,
								id: Number(borrador.id),
							};
						})
						.sort((a, b) => {
							return a.id < b.id ? 1 : -1;
						});
					this.loading = false;
				})
				.catch(({ status }) => {
					if (status == 401) {
						alert("La sesión ha expirado");
						this.$store.dispatch("logout");
					}
					this.loading = false;
				});
		},
		imprimirBorrador() {
			this.generatingPDF = true;
			this.tipoFirma = true;
		},
		generarPDF(e, firma) {
			this.generatingPDF = true;
			this.firmaDigital = false;
			this.tipoFirma = false;
			// console.log(firma);
			axios({
				method: "get",
				url: `${process.env.VUE_APP_OUR_API_URL}/generar_pdf.php`,
				params: {
					id: this.imprimirId,
					// empresa: this.$store.getters.getNombreEmpresa,
					empresa: this.contratos.find((c) => c.id == this.imprimirId).empresa,
					token: this.$store.getters.getJwtEmpresa,
					// firma : firma || null
				},
				headers: { "Access-Control-Allow-Origin": location.origin },
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`borrador_${new Date().getTime()}.pdf`,
						{
							type: "application/pdf",
						}
					);
					downloadFile(pdf, true);
					this.generatingPDF = false;
				})
				.catch((err) => {
					console.error(err);
					this.generatingPDF = false;
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
		_parseDate(date) {
			if (date == null) return "";
			if (date.indexOf("-") > -1) return parseDate(date);
			const [day, month, year] = date.split("/");
			const MESES = [
				"Enero",
				"Febrero",
				"Marzo",
				"Abril",
				"Mayo",
				"Junio",
				"Julio",
				"Agosto",
				"Septiembre",
				"Octubre",
				"Noviembre",
				"Diciembre",
			];

			return [day, MESES[month - 1], year].join(" ");
		},
		exportarTodos(items) {
			const getDatatype = (key) =>
				(this.contratosHeader.find((h) => h.value == key) || {}).dataType;

			if (!Array.isArray(items))
				items = this.contratos.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				);

			const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

			jsonToCsv(items, csvHeaders, 'MOROSOS_' + parseDate(Date.now(), false, false));
		},
	},
	mounted() {
		if (this.$root.acceso('VER_TODOS_CONTRATOS')) {
			this.contratosHeader.splice(2, 0, {
				text: "Empresa",
				value: "empresa",
				dataType: "select",
				class: "text-no-wrap sticky-header",
				cellClass: "pa-2 text-no-wrap",
				filter: (value) =>
					perColumnFilter(value, this.inlineFilters.empresa, "select"),
			});
		}
		this.getBorradores();
	},
};
</script>

<style scoped>
#signature {
	border: double 3px transparent;
	border-radius: 5px;
	background-image: linear-gradient(white, white),
		radial-gradient(circle at top left, #4bc5e8, #9f6274);
	background-origin: border-box;
	background-clip: content-box, border-box;
}
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>
